import React, { useCallback, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogImage from "./BlogImage";
import { Link } from "gatsby";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

const Blog = ({ pageData, locale }) => {
  const queryData = useStaticQuery(graphql`
    query {
      NoImage: file(relativePath: { eq: "no-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 210) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allIndustries: allStrapiIndustry {
        edges {
          node {
            id
            value
            blogs {
              id
              title
            }
          }
        }
      }
      allTechnologies: allStrapiTechnologyList {
        edges {
          node {
            value
            id
            blogs {
              id
              title
            }
          }
        }
      }
      allServices: allStrapiServiceList {
        edges {
          node {
            value
            id
            blogs {
              id
              title
            }
          }
        }
      }

      allStrapiBlog(sort: { date: ASC }, limit: 1000) {
        edges {
          node {
            id
            locale
            slug
            createdAt
            date(formatString: "DD MMMM YYYY")
            time
            title
            heading1
            preview {
              data {
                preview
              }
            }
            id
            Author {
              name
            }
            blogimg {
              ext
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 210) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            detailDescription {
              data {
                detailDescription
              }
            }
          }
        }
      }
    }
  `);
  const allIndustries = queryData.allIndustries.edges.filter(
    (item) => !!item.node.value
  );
  const allTechnologies = queryData.allTechnologies.edges.filter(
    (item) => !!item.node.value
  );
  const duplicatedServices = queryData.allServices.edges.filter(
    (item) => !!item.node.value
  );
  const allBlogs = queryData.allStrapiBlog.edges
    .map((item) => item.node)
    .filter((item) => item.locale === locale);
  const allServices = [];
  duplicatedServices.forEach((service) => {
    if (!allServices.find((item) => item.node.value === service.node.value)) {
      allServices.push(service);
    }
  });

  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSubOption, setSelectedSubOption] = useState([]);
  const [showLists, setShowLists] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState(allBlogs);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [skip, setSkip] = useState(15);

  const fetchMore = useCallback(() => {
    setSkip(skip + 15);
  }, [skip]);

  const handleChangeMainCategory = useCallback(
    (category) => {
      setSkip(15);
      setSelectedBlogs([]);
      if (selectedOption.includes(category)) {
        setSelectedOption(
          selectedOption.filter((option) => option !== category)
        );
        let updatedSubOption = selectedSubOption;
        let blogs = [];
        if (category === "Industry") {
          allIndustries.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        if (category === "Technology") {
          allTechnologies.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        if (category === "Service") {
          allServices.forEach((item) => {
            updatedSubOption = updatedSubOption.filter((ele) => ele !== item);
          });
        }
        setSelectedSubOption(updatedSubOption);
        if (updatedSubOption.length > 0) {
          const categories = updatedSubOption.map((item) => item.node.value);
          const filteredBlogs = allBlogs.filter((blog) =>
            categories.includes(blog.title)
          );
          setFilteredBlogs(filteredBlogs);
        } else {
          setFilteredBlogs(allBlogs);
        }
      } else {
        setSelectedOption([...selectedOption, category]);
        let subs = [];
        if (category === "Industry") {
          subs = allIndustries;
        }
        if (category === "Technology") {
          subs = allTechnologies;
        }
        if (category === "Service") {
          subs = allServices;
        }
        setSelectedSubOption([...selectedSubOption, ...subs]);
        if ([...selectedSubOption, ...subs].length > 0) {
          const categories = [...selectedSubOption, ...subs].map(
            (item) => item.node.value
          );
          const filteredBlogs = allBlogs.filter((blog) =>
            categories.includes(blog.title)
          );
          setFilteredBlogs(filteredBlogs);
        } else {
          setFilteredBlogs(allBlogs);
        }
      }
    },
    [selectedOption, selectedSubOption, allBlogs]
  );

  const handleChangeSubCategory = useCallback(
    (subCategory) => {
      setSkip(15);
      let updatedSubOption = [];
      if (selectedSubOption.includes(subCategory)) {
        updatedSubOption = selectedSubOption.filter(
          (option) => option !== subCategory
        );
      } else {
        updatedSubOption = [...selectedSubOption, subCategory];
      }
      setSelectedSubOption(updatedSubOption);
      if (updatedSubOption.length > 0) {
        const categories = updatedSubOption.map((item) => item.node.value);
        const filteredBlogs = allBlogs.filter((blog) =>
          categories.includes(blog.title)
        );
        setFilteredBlogs(filteredBlogs);
      } else {
        setFilteredBlogs(allBlogs);
      }
    },
    [selectedSubOption, allBlogs]
  );

  useEffect(() => {
    setSelectedBlogs(filteredBlogs.slice(0, skip));
  }, [filteredBlogs, skip]);

  return (
    <div className="blogPageContainer">
      <div className="categoryContainer">
        <h3>{pageData.categories}</h3>
        <ul className="categoryContent">
          <li>
            <div className="categoryItem">
              {showLists.includes("Industry") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Industry")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Industry"])}
                />
              )}
              <input
                type="checkbox"
                name="Industry"
                checked={selectedOption.includes("Industry")}
                onChange={() => handleChangeMainCategory("Industry")}
              />
              <a href="/tag/Industry/">{pageData.industry}</a>
            </div>
            <ul
              className={
                showLists.includes("Industry")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allIndustries.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Industry/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Technology") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Technology")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Technology"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Technology")}
                onChange={() => handleChangeMainCategory("Technology")}
              />
              <a href="/tag/Technology/">{pageData.technology}</a>
            </div>
            <ul
              className={
                showLists.includes("Technology")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allTechnologies.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Technology/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Service") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(showLists.filter((list) => list !== "Service"))
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Service"])}
                />
              )}
              <input
                type="checkbox"
                name="Service"
                checked={selectedOption.includes("Service")}
                onChange={() => handleChangeMainCategory("Service")}
              />
              <a href="/tag/Service/">{pageData.service}</a>
            </div>
            <ul
              className={
                showLists.includes("Service")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allServices.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Service/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div className="blogListContainer">
        {selectedBlogs && selectedBlogs.length > 0 ? (
          <>
            {selectedBlogs.map((node) => (
              <Link
                to={`/resource/blog/${node.slug}`}
                className="blogItem"
                key={node.id}
              >
                <BlogImage data={node} />
                <h3>{node.heading1}</h3>
              </Link>
            ))}
            {filteredBlogs.length > skip && (
              <div className="loadMoreContainer">
                <div onClick={() => fetchMore()} className="loadMoreBtn">
                  Load More
                </div>
              </div>
            )}
          </>
        ) : (
          <p>{pageData.noPost}</p>
        )}
      </div>
      <div className="mobileCategoryContainer">
        <h3>{pageData.categories}</h3>
        <ul className="categoryContent">
          <li>
            <div className="categoryItem">
              {showLists.includes("Industry") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Industry")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Industry"])}
                />
              )}
              <input
                type="checkbox"
                name="Industry"
                checked={selectedOption.includes("Industry")}
                onChange={() => handleChangeMainCategory("Industry")}
              />
              <a href="/tag/Industry/">{pageData.industry}</a>
            </div>
            <ul
              className={
                showLists.includes("Industry")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allIndustries.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Industry/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Technology") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(
                      showLists.filter((list) => list !== "Technology")
                    )
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Technology"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Technology")}
                onChange={() => handleChangeMainCategory("Technology")}
              />
              <a href="/tag/Technology/">{pageData.technology}</a>
            </div>
            <ul
              className={
                showLists.includes("Technology")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allTechnologies.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Technology/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
          <li>
            <div className="categoryItem">
              {showLists.includes("Service") ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  onClick={() =>
                    setShowLists(showLists.filter((list) => list !== "Service"))
                  }
                />
              ) : (
                <IoIosArrowDropupCircle
                  size={20}
                  onClick={() => setShowLists([...showLists, "Service"])}
                />
              )}
              <input
                type="checkbox"
                name="Technology"
                checked={selectedOption.includes("Service")}
                onChange={() => handleChangeMainCategory("Service")}
              />
              <a href="/tag/Service/">{pageData.service}</a>
            </div>
            <ul
              className={
                showLists.includes("Service")
                  ? "subContainer"
                  : "subContainerHidden"
              }
            >
              {allServices.map((item) => (
                <div className="categoryItem" key={item.node.value}>
                  <input
                    type="checkbox"
                    name={item.node.value}
                    checked={selectedSubOption.includes(item)}
                    onChange={() => handleChangeSubCategory(item)}
                  />
                  <a
                    href={`/tag/Technology/${item.node.value
                      .replace(" ", "-")
                      .replace("/", "_")}`}
                  >
                    {item.node.value}
                  </a>
                </div>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Blog;
